import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';
import { singleSpaPropsSubject } from 'src/single-spa/single-spa-props';

@Component({
  selector: 'app-sidenav-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  wrapperClass: string = "";

  ngOnInit() {
    console.log("Inside SIT env", environment.isSIT)
    singleSpaPropsSubject.subscribe((props: any) => {
      this.wrapperClass = props.wrapperClass;
    });
  }
}
