export const baseUrl = 'https://universalportal-sso.qacitizensbank.com/';
export const assetPrefix = "/sidenav-mfe/";
export const assetURL = `${baseUrl}${assetPrefix}`;

export const environment = {
  production: false,
  isQA: true,
  isQA2: false,
  isSIT: false,
  isDEV: false,

  sideNavLink: [
    { text: "Dashboard", id: "home", iconURL: `${assetURL}assets/cbds-icons-brand.svg#home`, route: "/sso/dashboard" },
    { text: "Corporate Card", id: "services", iconURL: `${assetURL}assets/cbds-icons-brand.svg#creditcard`, route: "/sso/dashboard/cards" },
    // { text: "Insights", id: "insights", iconURL: `${assetURL}assets/cbds-icons-brand.svg#chart-pie`, route: "insights" },
    // { text: "Digital Butler", id: "digital-butler", iconURL: `${assetURL}assets/cbds-icons-brand.svg#digital-butler`, route: "digital-butler" }
  ],

  sideNavLinkPrivate: [
    { text: "Dashboard", id: "home", iconURL: `${assetURL}assets/cbds-icons-brand.svg#home`, route: "/" },
    { text: "Corporate Card", id: "services", iconURL: `${assetURL}assets/cbds-icons-brand.svg#dashboard`, route: "services" },
    // { text: "Insights", id: "insights", iconURL: `${assetURL}assets/cbds-icons-brand.svg#chart-pie`, route: "insights" },
  ]
};
