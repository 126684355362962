import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { assetURL, environment } from 'src/environments/environment';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html'
})
export class SidenavComponent {

  navbarLinks = environment.sideNavLink;

  get assetURL() {
    return assetURL;
  }

  constructor(private router: Router) {
    const privateUser = sessionStorage.getItem('privateUser');

    if(privateUser === 'true') {
      this.navbarLinks = environment.sideNavLinkPrivate
    } else {
      this.navbarLinks = environment.sideNavLink;

    }
  }

  // using window.location since route.url is in sidenav does not have route info and is always '/'.
  get currentRoute() {
    return window.location.pathname;
  }

  handleClick(item: any) {
  }
}
