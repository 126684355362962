<!-- <div class="sidenav-wrapper">
    <div class="links">
        <div
            *ngFor="let link of navbarLinks"
            class="link"
            (click)="handleClick(link)"
        >
            <span class="link-image">
                <svg class="svg-icon icon-{{link.id}}" focusable="false" role="img" aria-label="{{link.text}}" data-reactroot="">
                    <use [attr.href]="link.iconURL" [attr.xlink:href]="link.iconURL"></use>
                </svg>
              </span>
            <span class="link-text">
                {{ link.text }}
            </span>
        </div>
    </div>
</div> -->

<aside id="sidenav" class="sidenav collapse">
  <ul class="nav-list">
    <li *ngFor="let link of navbarLinks">
      <a class="navbar-item" [href]="link.route" [ngClass]="{'active-nav': currentRoute === link.route}">
        <span class="nav-icon">
          <svg class="svg-icon" focusable="false" role="img" aria-label="{{link.text}}">
            <use [attr.href]="link.iconURL" [attr.xlink:href]="link.iconURL"></use>
          </svg>
        </span>
        <span class="nav-label">
          {{ link.text}}
        </span>
      </a>
    </li>
  </ul>
</aside>
